<script setup lang="ts">
import { useAuthStore } from '@/store/auth'
import { computed } from "vue"
import { useRoute } from "vue-router"
import MainMenuConfig from "@/layouts/config/MainMenuConfig"
import KTIcon from "@/helpers/kt-icon/KTIcon.vue"

const authStore = useAuthStore()
const route = useRoute()

const hasActiveChildren = (match: string) => {
    return route.path.indexOf(match) !== -1
}

const canAccess = (isPublicRoute: boolean) => {
    if (isPublicRoute) {
        return !isAuthenticated.value
    } else {
        return isAuthenticated.value
    }
}

const isAuthenticated = computed<boolean>(() => {
    return authStore.getAccessToken !== undefined
})
</script>

<template>
    <!--begin::Menu wrapper-->
    <div
        class="header-menu align-items-stretch"
        data-kt-drawer="true"
        data-kt-drawer-name="header-menu"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
        data-kt-drawer-close="#kt_header_menu_mobile_toggle_close"
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
        <!--begin::Menu-->
        <div
            id="#kt_header_menu"
            class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch"
            data-kt-menu="true">

            <!--begin::Mobile close button-->
            <div class="mobile-menu-close-button d-lg-none">
                <div
                    id="kt_header_menu_mobile_toggle_close"
                    class="btn btn-sm btn-icon btn-active-icon-primary">
                    <KTIcon
                        icon-name="cross"
                        icon-class="fs-3x"/>
                </div>
            </div>
            <!--end::Mobile close button-->

            <div
                v-if="!isAuthenticated"
                class="menu-item me-lg-1">
                <NuxtLink
                    class="menu-link"
                    to="/"
                    active-class="active">
                    <span class="menu-icon d-lg-none">
                        <KTIcon
                            icon-name="home"
                            icon-class="fs-1"/>
                    </span>
                    <span class="menu-title">
                        {{ $t(`home`) }}
                    </span>
                </NuxtLink>
            </div>

            <template v-for="item in MainMenuConfig">
                <template
                    v-for="menuItem in item.pages">
                    <template v-if="menuItem.pages">
                        <div
                            v-if="canAccess(menuItem.publicRoute)"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-start"
                            class="menu-item me-lg-1 p-1">
                            <span
                                v-if="menuItem.route"
                                class="menu-link"
                                :class="{ active: hasActiveChildren(menuItem.route) }">
                                <span class="menu-icon d-lg-none">
                                    <KTIcon
                                        v-if="menuItem.keenthemesIcon"
                                        :icon-name="menuItem.keenthemesIcon"
                                        icon-class="fs-2"/>
                                </span>
                                <span class="menu-title">{{ $t(menuItem.heading) }}</span>
                                <span class="menu-arrow d-lg-none"></span>
                            </span>
                            <div
                                class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                <template v-for="subMenuItem in menuItem.pages">
                                    <template v-if="subMenuItem.pages">
                                        <div
                                            v-if="subMenuItem.pages"
                                            data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                                            data-kt-menu-placement="right-start"
                                            class="menu-item menu-lg-down-accordion">
                                            <span
                                                v-if="subMenuItem.route"
                                                class="menu-link py-3"
                                                :class="{ active: hasActiveChildren(subMenuItem.route) }">
                                                <span class="menu-title">{{ $t(subMenuItem.heading) }}</span>
                                                <span class="menu-arrow"></span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div
                                            v-if="subMenuItem.heading"
                                            class="menu-item">
                                            <NuxtLink
                                                v-if="subMenuItem.route"
                                                class="menu-link"
                                                active-class="active"
                                                :to="subMenuItem.route">
                                                <span class="menu-icon d-lg-none ps-9 ps-lg-0">
                                                    <KTIcon
                                                        v-if="subMenuItem.keenthemesIcon"
                                                        :icon-name="subMenuItem.keenthemesIcon"
                                                        icon-class="fs-2"/>
                                                </span>
                                                <span class="menu-title ps-3 ps-lg-0">{{ $t(subMenuItem.heading) }}</span>
                                            </NuxtLink>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            v-if="canAccess(menuItem.publicRoute)"
                            class="menu-item me-lg-1 p-1">
                            <NuxtLink
                                v-if="menuItem.route"
                                class="menu-link"
                                :to="menuItem.route"
                                active-class="active">
                                <span class="menu-icon d-lg-none">
                                    <KTIcon
                                        v-if="menuItem.keenthemesIcon"
                                        :icon-name="menuItem.keenthemesIcon"
                                        icon-class="fs-1"/>
                                </span>
                                <span class="menu-title">
                                    {{ $t(menuItem.heading) }}
                                </span>
                            </NuxtLink>
                        </div>
                    </template>
                </template>
            </template>
            <!--end::Menu-->
        </div>
    </div>
    <!--end::Menu wrapper-->
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

 .mobile-menu-close-button {
     position: relative;
     left: 200px;
     padding-bottom: 10px;
 }

 .drawer {
     overflow: hidden !important;
 }
</style>
